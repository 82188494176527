.question {
	margin: 40px 0;
	display: block;
	border-bottom: 1px solid #f7f7f7;
	padding-bottom: 15px;
}

.questionTitle:before {
	content: attr(data-count);
	display: inline-flex;
	align-items: center;
	justify-content: center;
	width: 25px;
	height: 25px;
	padding: 5px;
	margin-right: 10px;
	border-radius: 50%;
	background: #6f8280;
	color: #fff;
	font-size: 14px;
}

.questionTitle {
	display: inline;
}

.questionSubTitle {
	font-size: 16px;
	margin-top: 10px;
	font-weight: normal;
}

.description {
	margin: 10px 0;
}

.buttons {
	display: flex;
	justify-content: space-between;
}

.progressBar {
	box-shadow: 1px 1px 4px 0px #bbbbbb;
	padding: 10px 15px;
	margin-top: 0;
	background: white;
	border-radius: 8px 8px 0 0;
}

.progressBar.stuck {
	border-radius: 0;
	box-shadow: none;
	padding: 0;
	margin-top: 25px;
}

/* Input styling */

.input {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	margin: 0;
	padding: 8px;
	font-variant: tabular-nums;
	list-style: none;
	font-feature-settings: "tnum";
	position: relative;
	display: inline-block;
	width: 100%;
	height: 38px;
	color: rgba(0, 0, 0, 0.65);
	font-size: 16px;
	line-height: 1.5;
	background-color: #fff;
	background-image: none;
	border: 1px solid #ababab;
	border-radius: 4px;
	transition: all 0.3s;
}

/* Bar */

.barWrap:before {
	content: " ";
	display: block;
	width: calc(100% + 10px);
	height: 10px;
	position: absolute;
	border-radius: 5px;
	background: #d3d3d3;
	box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.4);
	top: 0;
	bottom: 0;
	transform: translateY(50%);
	margin-left: -10px;
}

.barWrap {
	position: relative;
	height: 20px;
	margin-left: -10px;
}

.radio,
.checkbox {
	position: absolute;
	z-index: -1;
	opacity: 0;
}

.radioWrapper,
.checkboxWrapper {
	position: relative;
	margin: 12px 0;
}

.radioLabel,
.checkboxLabel {
	display: inline-flex;
	background: #f9f9f9;
	padding: 6px 8px;
	transition: 0.25s ease-in-out all;
	cursor: pointer;
	align-items: center;
	border-radius: 3px;
	width: 400px;
}

.radioLabel:hover,
.checkboxLabel:hover {
	background: #f3f3f3;
}

.radioLabel:before,
.checkboxLabel:before {
	content: "";
	background: #fafafa;
	border-radius: 50%;
	position: relative;
	border: 2px solid #7b7b7b;
	width: 26px;
	height: 26px;
	display: flex;
	align-items: center;
	pointer-events: none;
	margin-right: 6px;
}

.checkboxLabel:before {
	border-radius: 0;
}

.radio:checked + .radioLabel:after {
	content: "";
	display: block;
	width: 14px;
	height: 14px;
	position: absolute;
	background: #2a5aad;
	border-radius: 50%;
	left: 14px;
}

.checkbox:checked + .checkboxLabel:after {
	content: "";
	pointer-events: none;
	display: block;
	left: 21px;
	top: 18px;
	width: 8px;
	height: 16px;
	border: solid #2a5aad;
	border-width: 0 4px 4px 0;
	position: absolute;
	transform: translate(-50%, -50%) rotate(45deg);
}

.radio:focus + .radioLabel,
.checkbox:focus + .checkboxLabel {
	outline: 2px solid rgba(42, 90, 173, 0.4);
}

.radioRow,
.checkboxRow {
	display: block;
}

.radioRow.inlineRadio,
.checkboxRow.inlineCheckbox {
	display: flex;
	flex-wrap: wrap;
}

.radioRow.inlineRadio .radioWrapper,
.checkboxRow.inlineCheckbox .checkboxWrapper {
	margin-right: 12px;
}

.radioRow.inlineRadio .radioWrapper:last-child,
.checkboxRow.inlineCheckbox .checkboxWrapper:last-child {
	margin-right: 0;
}

@media screen and (max-width: 768px) {
	.radioRow.inlineRadio {
		display: block;
	}
	.checkboxRow.inlineCheckbox {
		display: block;
	}
}

/*
	RANGE SLIDER
*/
.rangeLabels {
	display: flex;
	justify-content: space-between;
	width: calc(100% + 15px);
	margin-left: -15px;
}

.rangeContainer {
	width: 100%;
	position: relative;
	margin: 10px 0;
	padding: 0 20px;
}

.rangeInput {
	-webkit-appearance: none;
	appearance: none;
	height: 100%;
	padding: 0;
	box-sizing: border-box;
	outline: none;
	z-index: 4;
	position: absolute;
	background: transparent;
	margin-left: -10px;
	margin-right: -10px;
	width: calc(100% + 20px);
}

/* Hide default browser styling */

.rangeInput::-webkit-slider-thumb {
	background: transparent;
	opacity: 0;
}

.slider::-moz-range-thumb {
	background: transparent;
	opacity: 0;
}

.rangeInput::-ms-fill-lower,
.rangeInput::-ms-fill-upper,
.rangeInput::-ms-thumb,
.rangeInput::-ms-track {
	background: transparent;
	color: transparent;
	border: 0;
	outline: 0;
}

.barWrap:before {
	content: " ";
	display: block;
	width: calc(100% + 10px);
	height: 10px;
	position: absolute;
	border-radius: 5px;
	background: #d3d3d3;
	box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.4);
	top: 0;
	bottom: 0;
	transform: translateY(50%);
	margin-left: -10px;
}

.barWrap {
	position: relative;
	height: 20px;
	margin-left: -10px;
}

.rangeProgress {
	position: absolute;
	background: #3e6cbb;
	border-radius: 5px 0 0 5px;
	z-index: 2;
	pointer-events: none;
	top: 0;
	bottom: 0;
	transform: translateY(50%);
	height: 10px;
	margin-left: -10px;
	max-width: 100% !important;
}

.rangeCircle {
	width: 20px;
	height: 20px;
	border-radius: 50%;
	background: #3e6cbb;
	position: absolute;
	top: 0;
	bottom: 0;
	border: 2px solid #13356f;
	z-index: 3;
	background: #104298;
	transform: translateX(-50%);
	cursor: grabbing;
}

.circleTooltip {
	visibility: hidden;
	width: 50px;
	background-color: rgba(0, 0, 0, 0.6);
	color: #fff;
	text-align: center;
	border-radius: 6px;
	padding: 5px 0;
	position: absolute;
	z-index: 1;
	bottom: 155%;
	left: -18px;
}

.circleTooltip:after {
	content: " ";
	position: absolute;
	top: 100%;
	/* At the bottom of the tooltip */
	left: 50%;
	margin-left: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: rgba(0, 0, 0, 0.6) transparent transparent transparent;
}

.barWrap:active .circleTooltip {
	visibility: visible;
}

.input {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	margin: 0;
	padding: 8px;
	font-variant: tabular-nums;
	list-style: none;
	font-feature-settings: "tnum";
	position: relative;
	display: inline-block;
	height: 38px;
	color: rgba(0, 0, 0, 0.65);
	font-size: 16px;
	line-height: 1.5;
	background-color: #fff;
	background-image: none;
	border: 1px solid #ababab;
	border-radius: 4px;
	transition: all 0.3s;
}

@media screen and (max-width: 500px) {
	.radioLabel {
		width: 300px;
	}
}
